<template>
  <div>
    <p class="p-3 text-lg font-bold "><span class="typcn typcn-edit"></span> Informasi Toko</p>
    <hr class="mt-1 mb-1">
    <form  @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class="col-9 p-2" style="font-size:12px;">
          <div class="shadow p-3 rounded-lg bg-white">
          <!-- INPUT -->
          <div class="sm-form">
            <input
              type="text"
              id="id"
              name="id"
              class="form-control form-control-sm"
              placeholder="id"
              v-model="datasetting.id"
              disabled
            />
          </div>
          <div class="row">
            <div class="col-sm-6">
              <table class="table table-sm table-responsive">
                 <tr>        
                    <td class="capitalize">Cabang Toko</td>
                    <td class="pl-2 pr-2">:</td>
                    <td>
                      <select class='form-control' v-model="datasetting.cabang">
                      <option v-for="(item, index) in cabangs" :key="index+'cabang'" :value="item.id">{{item.cabang}}</option>
                      </select>
                    </td>
                </tr>
              <tr>        
                 <td class="capitalize">Nama Shop/Toko</td>
                 <td class="pl-2 pr-2">:</td>
                 <td>
                     <div class="sm-form ">
                         <input type="text" id="nama_toko" name="nama_toko" class="form-control form-control-sm" placeholder="nama_toko" v-model="datasetting.nama_toko" >
                     </div>
                 </td>
             </tr>
              <tr>        
                 <td class="capitalize">Nama owner</td>
                 <td class="pl-2 pr-2">:</td>
                 <td>
                     <div class="sm-form ">
                         <input type="text" id="owner" name="owner" class="form-control form-control-sm" placeholder="owner" v-model="datasetting.owner" >
                     </div>
                 </td>
             </tr>
              <tr>        
                 <td class="capitalize">alamat</td>
                 <td class="pl-2 pr-2">:</td>
                 <td>
                    <div class="sm-form">
                    <textarea
                    type="text"
                    id="alamat"
                    name="alamat"
                    rows="2"
                    placeholder="alamat..."
                    class="form-control md-textarea"
                    v-model="datasetting.alamat"
                    ></textarea>
                    </div>
                 </td>
             </tr>
              <tr>        
                 <td class="capitalize">Link Embeded map</td>
                 <td class="pl-2 pr-2">:</td>
                 <td>
                     <div class="sm-form ">
                         <input type="text" id="map" name="map" class="form-control form-control-sm" placeholder="map" v-model="datasetting.map" >
                     </div>
                 </td>
             </tr>
              <tr>        
                 <td class="capitalize">no Telp</td>
                 <td class="pl-2 pr-2">:</td>
                 <td>
                     <div class="sm-form ">
                         <input type="text" id="no_telp" name="no_telp" class="form-control form-control-sm" placeholder="no_telp" v-model="datasetting.no_telp" >
                     </div>
                 </td>
             </tr>
              <tr>        
                 <td class="capitalize">keterangan</td>
                 <td class="pl-2 pr-2">:</td>
                 <td>
                   <div class="sm-form">
                   <textarea
                   type="text"
                   id="keterangan"
                   name="keterangan"
                   rows="2"
                   placeholder="keterangan..."
                   class="form-control md-textarea"
                   v-model="datasetting.keterangan"
                   ></textarea>
                   </div>
                 </td>
             </tr>
              <tr>        
                 <td class="capitalize">Jam Operational</td>
                 <td class="pl-2 pr-2">:</td>
                 <td>
                   <div class="sm-form">
                   <textarea
                   type="text"
                   id="jam_operational"
                   name="jam_operational"
                   rows="2"
                   placeholder="jam_operational ..."
                   class="form-control md-textarea"
                   v-model="datasetting.jam_operational"
                   ></textarea>
                   </div>
                 </td>
             </tr>
         </table>
            </div>
            <div class="col-sm-6">
              <table class="table table-sm table-responsive">
                 <tr>        
                 <td class="capitalize">facebook</td>
                 <td class="pl-2 pr-2">:</td>
                 <td>
                     <div class="sm-form ">
                         <input type="text" id="facebook" name="facebook" class="form-control form-control-sm" placeholder="facebook" v-model="datasetting.facebook" >
                     </div>
                 </td>
             </tr>
              <tr>        
                 <td class="capitalize">instagram</td>
                 <td class="pl-2 pr-2">:</td>
                 <td>
                     <div class="sm-form ">
                         <input type="text" id="instagram" name="instagram" class="form-control form-control-sm" placeholder="instagram" v-model="datasetting.instagram" >
                     </div>
                 </td>
             </tr>
              <tr>        
                 <td class="capitalize">youtube</td>
                 <td class="pl-2 pr-2">:</td>
                 <td>
                     <div class="sm-form ">
                         <input type="text" id="youtube" name="youtube" class="form-control form-control-sm" placeholder="youtube" v-model="datasetting.youtube" >
                     </div>
                 </td>
             </tr>
              <tr>        
                 <td class="capitalize">logo</td>
                 <td class="pl-2 pr-2">:</td>
                 <td>
                     <div class="sm-form ">
                         <input type="file" id="filenya" name="logo" class="form-control form-control-sm" placeholder="logo" @change="upload">
                         <input type="text" id="logo" name="logo" class="form-control form-control-sm" placeholder="logo" v-model="datasetting.logo" >
                     </div>
                 </td>
             </tr>
              <tr>        
                 <td class="capitalize">Warna Title</td>
                 <td class="pl-2 pr-2">:</td>
                 <td>
                    <div class="sm-form ">
                       <label for="warna_title">Warna Title</label>
                       <input type="color" id="warna_title" name="warna_title" class="form-control form-control-sm" placeholder="warna_title" v-model="datasetting.warna_title" >
                   </div>
                 </td>
             </tr>
               <tr>        
                 <td class="capitalize">Warna admin dashboard</td>
                 <td class="pl-2 pr-2">:</td>
                 <td>
                   <div class="sm-form ">
                       <label for="warna_admin">Warna SVG Admin</label>
                       <input type="color" id="warna_admin" name="warna_admin" class="form-control form-control-sm" placeholder="warna_admin" v-model="datasetting.warna_admin" >
                   </div>
                 </td>
             </tr>
                <tr>        
                 <td class="capitalize">Warna Landing Board Column</td>
                 <td class="pl-2 pr-2">:</td>
                 <td>
                   <div class="sm-form ">
                       <label for="warna_landingpage">Warna SVG Landing Page</label>
                       <input type="color" id="warna_landingpage" name="warna_landingpage" class="form-control form-control-sm" placeholder="warna_landingpage" v-model="datasetting.warna_landingpage" >
                   </div>
                 </td>
             </tr>
              </table>
            </div>
          </div>
         
          <!-- END INPUT -->
          <!-- BUTTON PART -->
          <div class="mt-3 p-3">
            <button
              type="submit"
              class="btn btn-sm"
              v-if="btn=='update'"
              :class="
                btn == 'tambah'
                  ? 'btn-primary disabled'
                  : btn == 'update'
                  ? 'btn-success'
                  : 'btn-danger'
              "
            >
              <span class="typcn typcn-info"></span> Proses  {{ btn }}
            </button>
            <div class="float-right">
              <!-- <button
                type="button"
                v-show="btn!='tambah'"
                @click="
                  btn = 'tambah';
                  datasetting = {};
                "
                class="btn btn-sm btn-dark"
              >
                <span class="typcn typcn-edit"></span> Tambah
              </button> -->
              <!-- <button
                type="button"
                @click="btn = 'delete'"
                v-show="btn!='tambah'"
                class="ml-3 btn btn-sm btn-danger"
              >
                <span class="typcn typcn-delete"></span> Delete
              </button> -->
            </div>
          </div>
          </div>
          <!-- END BUTTON PART -->
        </div>
        <div class="col-7 d-none" style="font-size:12px;">
          <div class="shadow d-none rounded-lg">
            <!-- TABLE PART -->
            <div v-if="datanya.length>0" class="p-3">
                <Btables :tables="datanya"  :cari="['']" :hide="['']" @selected="ambil" ref="btable" />
            </div>
            <!-- END TABLE PART -->
         </div>
        </div>
      </div>
    </form>
     <div class="d-block d-sm-none"><br><br><br><br></div>
  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
export default {
  components: {
    Btables,
  },
  data() {
    return {
      selected: false,
      datasetting: {},
      btn: "update",
      cabangs:[],
      datanya:[],
      listcolor:[
        'gray',
        'red',
        'yellow',
        'green',
        'blue',
        'purple',
        'pink',
        'orange',
        'black',
      ]
    };
  },
  layout:'app_shop_admin',
  methods: {
    upload(){
      let file = document.querySelector("#filenya"); // berikan id pada input file
            sdb.collection('tbuser').upload(file).then(res=>{
                console.log(res)
                this.datasetting.logo=res;
                this.$forceUpdate();
            })
    },
    app_kasir_toko(){
      this.$refs.btable.getData()
    },
    getData(){
      sdb.collection("app_kasir_toko",false).doc().select(`select * from app_kasir_toko`).then(res => {
        this.datanya=res;
        this.datasetting=res[0]
        this.$store.state.data.toko=res[0];
        this.$forceUpdate();
      });
      
    },
    getCabang(){
      sdb.collection("app_kasir_toko_cabang",false).doc().select(`select * from app_kasir_toko_cabang`).then(res => {
          this.cabangs=res;
          this.$forceUpdate();
      });
      
    },
    ambil(data) {
      this.datasetting = data;
      this.btn = "update";
      this.$forceUpdate();
    },
    submit() {
      let that=this
      if (confirm("Apakah yakin proses dilanjutkan ?")) {
        if (this.btn == "tambah") {
          //idnya letak di datasetting
          delete this.datasetting.id;
          sdb
            .collection("app_kasir_toko")
            .doc()
            .set(this.datasetting)
            .then((res) => {
              that.getData();
            // that.$nuxt.$emit('getData')

            });
        } else if (this.btn == "update") {
          sdb
            .collection("app_kasir_toko")
            .doc()
            .set(this.datasetting)
            .then((res) => {
              that.getData();
            // that.$nuxt.$emit('getData')
            });
        } else if (this.btn == "delete") {
          sdb
            .collection("app_kasir_toko")
            .doc(this.datasetting.id)
            .delete()
            .then((res) => {
              that.getData();
            // that.$nuxt.$emit('getData')
            });
        }
        // this.$nuxt.$emit('getData','ok')
      }
    },
  },
  async mounted() {
    this.getData();
    this.getCabang();
  },
};
</script>